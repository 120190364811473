import React from "react"
import { Link } from "gatsby"
import Layout from "../components/shared/layout"
import SEO from "../components/shared/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Page not found.</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default NotFoundPage
